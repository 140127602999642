import PageFoot from "@/components/page_foot";
import PageHead from "@/components/page_head";
import getFootLink from "@/utils/getFootLink";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from "react";
interface MainLayoutProps {
  children?: React.ReactNode;
  navData: Nav[];
  websiteInfo: SiteInfo;
}
const MainLayout: ForwardRefRenderFunction<MainRef, MainLayoutProps> = ({
  children,
  navData,
  websiteInfo,
}, ref) => {


  const { activityLink, newsLink } = getFootLink(navData);

  const headRef = useRef<HeaderRef>(null);
  useImperativeHandle(ref, () => {
    return {
      // ... 你的方法 ...
      onLogin: (expired = false) => {
        headRef.current?.onLogin(expired);
      },
    };
  });

  

  return (
    <>
      <PageHead navData={navData} ref={headRef} />
      <main role="main" className="main">{children}</main>
      <PageFoot
        activityLink={activityLink}
        newsLink={newsLink}
        websiteInfo={websiteInfo}
      />
    </>
  );
}

export default forwardRef(MainLayout);

// 创建一个useBrowse hook，使用web worker的方式 进行埋点请求 以及数据上报传入的参数
import { token } from '@/store/atoms/global';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect } from 'react';

interface BrowseData {
    url: string;
    data?: Data;
    // 上一个页面的地址
    prePath?: string;
    // 是否手动执行
    manual?: boolean;
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    onFinally?: () => void;
}

interface Data {
    dataId?: number;
    dataType: number;
    prePage?: string;
    thirdChannelId: number;
}

export default function useBrowse({ url, data: optionData, manual = false, prePath = '', onError, onFinally, onSuccess }: BrowseData) {
    const tokenKey = useAtomValue(token);

    const runWorker = useCallback(async (worker: Worker, data?: any) => {
        // 监听worker的消息
        worker.onmessage = (event) => {
            console.log('browse success', event);
            const { data } = event;
            if (data.status === 'success') {
                // console.log('埋点请求成功：', data.result);
                onSuccess && onSuccess(data.result);
            } else {
                // console.log('埋点请求失败：', data.result);
                onError && onError(data.result);
            }
            onFinally && onFinally();
        };

        let headers = {};

        if (tokenKey) {
            headers = { "tokenKey": tokenKey };
        }
        worker.postMessage({ url, data: { ...data, prePage: prePath }, headers });
    }, [url, prePath, tokenKey, onError, onFinally, onSuccess]);

    useEffect(() => {

        if (!manual) {

            const worker = new Worker('/workers/worker.js');
            console.log('Worker created');

            runWorker(worker, optionData);

            return () => {
                console.log('Terminating worker');
                worker.terminate();
            };
        }

    }, [manual, optionData, runWorker]);

    // 手动执行
    const run = useCallback(async (data: Data) => {
        // 如果不是手动执行，直接返回
        if (!manual) return;
        // 手动执行时，创建一个新的worker
        const worker = new Worker('/workers/worker.js');
        await runWorker(worker, data);
        return worker;
    }, [manual, runWorker]);

    return {
        // 手动执行
        run,
    };
}
import styles from "./PageHead.module.css";
import Image from "@/components/image";
import Link from "@/components/link";
import logo from "@/public/assets/images/logo01.png";
import logo2 from "@/public/assets/images/logo02.png";
import logo3 from "@/public/assets/images/logo03.png";
import search from "@/public/assets/images/searchIcon.png";
import lmdt from "@/public/assets/images/icon2.png";
import gjzx from "@/public/assets/images/icon1.png";
import scan from "@/public/assets/images/scan.png";
import { forwardRef, ForwardRefRenderFunction, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import upLogo from "@/public/assets/images/upLogo.png";
import { useRouter } from "next/router";
import { App, Button, Divider, Dropdown, Form, GetRef, Input, Modal, Select, Space } from "antd";
import websiteAxios from "@/serve/websiteAxios";
import { CODE_STATUS, USER_TYPE } from "@/constants";
import { debounce } from 'lodash';
import { useAtom, useAtomValue } from "jotai";
import { isLoginAtom, token, userInfo } from "@/store/atoms/global";
import defaultPhoto from "@/public/assets/images/defaultPhoto.png";
import { CaretDownOutlined } from "@ant-design/icons";
import Iconfont from "@/components/iconfont";


// 渲染国家财政专项和中心评审的内容
const UpwardsItem = (item: {
  image: string;
  title: string;
  content: ReactNode;
}) => (
  <>
    <div className={styles.upImage_wrapper}>
      <Image
        src={item.image}
        alt={item.title}
        fill
        className={styles.upImage}
      />
    </div>
    <h3>{item.title}</h3>
    <p>{item.content}</p>
  </>
);
interface HospitalSelectProps {
  id?: string;
  value?: number;
  onChange?: (value: number) => void;
}
type SelectRefType = GetRef<typeof Select>; // BaseSelectRef
const HospitalSelect = ({ id, value: propsValue, onChange }: HospitalSelectProps) => {
  // 所属医院
  const [options, setOptions] = useState<Hospital[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [value, setValue] = useState<number | undefined>(propsValue);
  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);
  const fetchRef = useRef(0);
  const selectRef = useRef<SelectRefType>(null);

  const loadOptions = (value: string) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setSearchValue(value);

    websiteAxios.get<any, any>('/api/common?endpoint=getHospital', { params: { keyword: value } })
      .then((res) => {

        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(res);
      }).catch((error) => {
        console.error('error', error);
      })
  };

  const debounceFetcher = debounce(loadOptions, 200);

  const onClickAddHospital = () => {
    setOptions([{ id: 0, name: searchValue }])
    setValue(0);
    onChange && onChange(0);
    selectRef.current?.blur();
  }

  const addHospital = (
    <div className={styles.addHospital}>
      无搜索结果，<span className={styles.blue} onClick={onClickAddHospital}>点击新增</span>
    </div>
  )

  return (
    <Select
      showSearch
      labelInValue
      id={id}
      ref={selectRef}
      value={value}
      placeholder="请选择所属医院"
      filterOption={false}
      getPopupContainer={triggerNode => triggerNode.parentElement}
      onSearch={debounceFetcher}
      notFoundContent={!options.length && searchValue ? addHospital : null}
      onChange={onChange}
    >
      {
        options.map((option) => (
          <Select.Option key={option.id} value={option.id}>
            {option.name}
          </Select.Option>
        ))
      }
    </Select>
  )
}

interface Hospital {
  id: number;
  name: string;
  provinceName?: string;
  cityName?: string;
  areaName?: string;
  idcard?: string;
  areacode?: number;
  level?: number;
  levelnum?: number;
  bednum?: number;
  abbreviation?: null;
  oldName?: null;
  intro?: null;
  hospitalType?: number;
  provinceCode?: number;
  cityCode?: number;
  orderNum?: number;
}
interface Illness {
  id: number;
  chineseName: string;
}
interface HeaderProps {
  navData: Nav[];
}

const PageHead: ForwardRefRenderFunction<HeaderRef, HeaderProps> = ({ navData }, ref) => {
  const { message } = App.useApp();
  const router = useRouter();
  const { query } = router;
  const [upwards, setUpwards] = useState<boolean>(false);
  const [centerReview, setCenterReview] = useState<boolean>(false);


  const CssTransitionRef = useRef<HTMLDivElement>(null);

  // 判断当前导航是否激活
  const isActive = (item: Nav) => {
    const isDetailPage = (type: string) =>
    (
      // 判断真实路径是否是详情页面
      router.asPath.startsWith("/detail") &&
      (
        // 判断一级导航是否存在等于type的
        item.link.includes(`/${type}`) ||
        // 判断二级导航是否存在等于type的
        item.navigationList?.some(v => v.link.includes(`/${type}`))
      ) &&
      // 判断真实路径是否包含type变量值
      router.asPath.includes(`/${type}`));
    // 拦截首页 例如：/
    if (router.asPath === "/" && item.link === "/") return true;
    // 拦截如果真实地址不是/ 首页不应该高亮 例如：/
    if (item.link === '/') return false;
    // 判断真实地址是否以当前link开头 例如：/news
    if (router.asPath.startsWith(item.link)) return true;
    // 当进入子导航时判断高亮 例如：/news/1
    if (item.navigationList?.length && item.navigationList?.some((subItem) => router.asPath.includes(subItem.link))) return true;
    // 判断进入详情页面的情况
    if (['activity', 'news', 'member', 'database', 'building'].some(isDetailPage)) return true;
    return false;
  }

  // 如果页面滚动，关闭下拉菜单
  useEffect(() => {
    // 判断滑动隐藏了顶部导航时关闭弹窗
    const handleScroll = () => {
      const header = document.getElementById("header");
      if (header && header.getBoundingClientRect().top < -header.clientHeight) {
        setUpwards(false);
        setCenterReview(false);
      }
    };
    if (query?.review) {
      setCenterReview(true);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 获取跳转导航链接
  const getHeaderLink = (item: Nav) => {
    if (item.navigationList?.length && item.navigationList[0].link !== '/index') {
      return item.link + item.navigationList[0].link;
    }
    return item.link;
  };

  // 点击其他地方关闭弹窗
  useEffect(() => {
    function handleClickOutside(event: any) {

      const checkAndSet = (id: string, setFunc: (value: boolean) => void) => {
        const element = document.getElementById(id);
        // 如果点击的是当前元素或者子元素，不关闭
        if (element && element.contains(event.target)) {
          return true;
        }
        // 如果点击的是其他元素，关闭
        if (!element?.contains(event.target)) {
          setFunc(false);
        }
        return false;
      }

      if (checkAndSet('popups_link', setUpwards)) {
        // 点击当前元素或者子元素，关闭中心评审
        setCenterReview(false)
        return;
      } else if (checkAndSet('center_review', setCenterReview)) {
        // 点击当前元素或者子元素，关闭国家财政专项
        setUpwards(false)
        return;
      }
    }

    // 绑定事件监听
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // 移除事件监听
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [CssTransitionRef, centerReview, upwards]);
  // 中心评审
  const renderCenterReview = () => (
    <li
      className={classNames(styles.nav_item, {
        [styles.active]: centerReview
      })}
      key="中心评审"
      id="center_review"
      onClick={() => {
        setCenterReview(true);
      }}
    >
      <span className={styles.nav_item_text}>中心评审</span>
      <CSSTransition
        key={"中心评审CssTransition"}
        nodeRef={CssTransitionRef}
        in={centerReview}
        timeout={200}
        unmountOnExit
        appear
        classNames="fade-in"
      >
        <section
          ref={CssTransitionRef}
          className={styles.float_wrapper}
        >
          <div className={styles.center_review_wrapper}>
            <Image
              src="https://hjblm-platform.chard.org.cn/rare_disease/image/202309/gVAt0t_1695283864278.png"
              className={styles.banner}
              width={1272}
              height={256}
              alt="中心评审"
            />
            <div className={styles.intro_wrapper}>
              <p className={styles.intro}>
                为推动多发性硬化(MS)和视神经脊髓炎谱系疾病(NMOSD)诊疗,促进单病种多中心的诊疗规范与协作,在国家卫生健康委医院管理研究所和国家罕见病质控中心指导下,罕见病联盟/北京罕见病诊疗与保障学会发起“MS/NMOSD中心建设”项目,以评促改，持续改善，逐步实现MS/NMOSD患者早期规范诊疗和分级协作管理。
              </p>
              <div className={styles.qrcode_box}>
                <Image
                  className={styles.qrcode}
                  src="https://hjblm-platform.chard.org.cn/rare_disease/image/202311/wbB3F6_1699326072681.jpg"
                  width={200}
                  height={200}
                  alt="中心评审小程序二维码"
                />
                <p className={styles.tip}>微信扫码提交申请意向</p>
              </div>
            </div>
            <Link href="https://app.chard.org.cn/app-login-admin-web/#/applist" className={styles.btn} target="_blank">
              立即访问
            </Link>
          </div>
        </section>
      </CSSTransition>
    </li>
  );
  // 国家财政专项
  const renderUpwardsNav = () => (
    <li
      className={classNames(styles.nav_item, {
        [styles.active]: upwards
      })}
      key="国家财政专项"
      id="popups_link"
      onClick={() => {
        setUpwards(true);
      }}
    >
      <span className={styles.nav_item_text}>国家财政专项</span>
      <CSSTransition
        key={"国家财政专项CssTransition"}
        nodeRef={CssTransitionRef}
        in={upwards}
        timeout={200}
        unmountOnExit
        appear
        classNames="fade-in"
      >
        <section
          ref={CssTransitionRef}
          className={classNames(styles.float_wrapper, styles.upwards)}
          style={{
            backgroundImage: `url(https://hjblm-platform.chard.org.cn/rare_disease/image/20233/upwards_bg.png)`,
          }}
        >
          <div className={styles.upwards_wrapper}>
            <Image
              src={upLogo}
              className={styles.upLogo}
              alt="国家财政专项upwards"
              width={565}
            />
            <p className={styles.intro}>
              为减轻国内罕见病患者及家庭临床诊治的经济负担，同时推进国内罕见病临床诊疗水平的进一步提高，由中央财政安排中央专项彩票公益金，支持北京协和医院牵头开展，面向国内罕见病患群体的“中央专项彩票公益金支持罕见病诊疗水平能力提升项目”。
            </p>
            <h2>项目主要包括以下工作</h2>
            <ul className={styles.up_list}>
              <li>
                <UpwardsItem
                  image="https://hjblm-platform.chard.org.cn/rare_disease/image/20233/ycjcxm.png"
                  title="罕见病多学科诊疗项目"
                  content="罕见病多学科诊疗项目包括开展疑难罕见病多学科诊疗、典型病例培训材料制作和多学科诊疗网络平台建设"
                />
              </li>
              <li>
                <UpwardsItem
                  image="https://hjblm-platform.chard.org.cn/rare_disease/image/20233/mdt_project.png"
                  title="遗传检测项目"
                  content="包括罕见病患者遗传检测和遗传检测标准制定"
                />
              </li>
              <li>
                <UpwardsItem
                  image="https://hjblm-platform.chard.org.cn/rare_disease/image/20233/train.png"
                  title="罕见病诊疗能力培训"
                  content="包括罕见病单病种培训和专题类培训"
                />
              </li>
            </ul>
            <div className={styles.btnGroup}>
              <Link href="https://upwards.chard.org.cn/" target="_blank">
                立即访问
              </Link>
              <Link href='/list/news/15/77'>
                财政专项动态
              </Link>
            </div>
          </div>
        </section>
      </CSSTransition>
    </li>
  );

  const [tokenKey, setTokenKey] = useAtom(token);
  useImperativeHandle(ref, () => {
    return {
      // ... 你的方法 ...
      onLogin: async (expired = false) => {
        if (tokenKey && !expired) return;
        // 未登录
        beforeLogin();
      },
    };
  }, [tokenKey]);

  useEffect(() => {
    const getUserInfo = async (tokenKey: string) => {
      const userRes = await websiteAxios.post<any, any>('/api/user?endpoint=userInfo', '', { headers: { tokenKey } }).catch((error) => {
        const { data } = error.response;
        if (data.code === CODE_STATUS.TOKEN_EXPIRED) {
          setTokenKey(null);
          setUser(null);
        }
      });
      // 保存用户信息
      if (userRes) {
        setUser(userRes);
      }
    };
    if (tokenKey) {
      getUserInfo(tokenKey);
    }
  }, [tokenKey]);

  // 登录
  const [loginVisible, setLoginVisible] = useState<boolean>(false);
  // 登录key
  const [loginKey, setLoginKey] = useState<string>('');
  // 二维码地址
  const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
  // 是否过期
  const [expired, setExpired] = useState<boolean>(false);

  const interval = useRef<NodeJS.Timeout | undefined>(undefined);
  const getUserInfo = async (tokenKey: string) => {
    const userRes = await websiteAxios.post<any, any>('/api/user?endpoint=userInfo', '', { headers: { tokenKey } }).catch((error) => {
      const { data } = error.response;
      if (data) {
        message.error(data.msg);
      }
    });;
    // 判断用户信息是否存在
    if (!userRes) {
      setUserVisible(true);
      return;
    }
    setUser(userRes);
    // 判断用户信息是否完善
    switch (userRes.status) {
      case 1:
      case 2:
      case 3:
        // 检查doctorCurrentHospitalId是否有值
        if (!userRes.doctorCurrentHospitalId) {
          setUserVisible(true);
        }
        break;
      case 5:
      case 8:
        if (!userRes.companyOrOrganization) {
          setUserVisible(true);
        }
        break;
      case 6:
        if (!userRes.universityName) {
          setUserVisible(true);
        }
        break;
      case 7:
        if (!userRes.confirmedDisease?.length) {
          setUserVisible(true);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const waveElement = document.querySelector(`.${styles.wave}`) as HTMLElement;
    if (loginVisible) {
      if (expired) return; // 过期时，不监听
      if (waveElement) {
        // 打开时，添加动画监听
        waveElement.style.removeProperty("display");
        let animationCount = 0;
        if (!waveElement) return; // 不存在时，不监听
        waveElement.addEventListener("animationiteration", () => {
          animationCount++;
          if (animationCount % 3 === 0) {
            // 3次动画结束后，移除元素
            waveElement.style.display = "none";
          }
        });
      }

      if (!loginKey) return;
      // 轮询请求登录状态

      interval.current = setInterval(async () => {
        const token = await websiteAxios.post<any, any>('/api/login?endpoint=queryLoginKey', { loginKey }).catch((error) => {
          const { data } = error.response;
          switch (data.code) {
            case CODE_STATUS.QRCODE_EXPIRED:
              // 二维码已失效
              clearInterval(interval.current);
              setExpired(true);
              break;
            default:
              message.error(data.msg);
              break;
          }
        });;
        if (!token) return;
        if (token) {
          setTokenKey(token);
          // 登录成功
          clearInterval(interval.current);
          setLoginVisible(false);
          message.success('扫码登录成功');
          getUserInfo(token);
        }
      }, 2000);

    } else {
      // 关闭时，移除事件监听
      const waveElement = document.querySelector(`.${styles.wave}`);
      if (waveElement) {
        waveElement.removeEventListener("animationiteration", () => { });
      }
      interval.current && clearInterval(interval.current)
    }
  }, [loginVisible, loginKey, expired, interval.current]);

  // 登录前判断
  const beforeLogin = async (refresh = false) => {
    const res = await websiteAxios.post<any, { qrCodeUrl: string; loginKey: string; }>('/api/login?endpoint=getQrCode').catch((error) => {
      console.error('error', error);
      const { data } = error.response;
      if (data) {
        message.error(data.msg);
      }
    });
    if (!res) return;
    setQrCodeUrl(res.qrCodeUrl);
    setLoginKey(res.loginKey);
    // 判断是否刷新二维码，如果刷新二维码的话不需要重置弹出层显示状态
    if (!refresh) {
      setLoginVisible(true);
    } else {
      setExpired(false);
    }
  };

  // 刷新二维码
  const refreshRQCode = () => {
    setQrCodeUrl('');
    setLoginKey('');
    beforeLogin(true);
  };

  const renderLoginModule = useMemo(() => (
    <Modal
      centered
      title={null}
      footer={null}
      open={loginVisible}
      onCancel={() => {
        setQrCodeUrl('');
        setLoginKey('');
        setExpired(false);
        setLoginVisible(false)
      }}
    >
      <div className={styles.loginTitle}>微信扫码登录</div>
      {/* expired */}
      {expired ? (
        <div className={styles.expired} onClick={refreshRQCode}>
          <Iconfont type="icon-shuaxin1" className={styles.icon} />
          <span>二维码已失效，点击请刷新</span>
        </div>
      ) : (
        <div className={styles['scan-wave']}>
          {qrCodeUrl && <Image
            src={qrCodeUrl}
            alt="二维码"
            className={styles.qrCode}
            width={200}
            height={200}
          />}
          <Image
            src={scan}
            className={styles.wave}
            alt=""
            width={200}
          />
        </div>
      )}
      <div className={styles.loginTip}>中国罕见病联盟官方小程序</div>
    </Modal>
  ), [loginVisible, qrCodeUrl, expired]);

  const [userVisible, setUserVisible] = useState<boolean>(false);
  const [userForm] = Form.useForm();
  // 用户信息提交
  const onUserFinish = useCallback(async ({
    doctorCurrentHospital,
    illness,
    ...values
  }: UserForm) => {
    const params: User = {
      ...values,
    }
    if (doctorCurrentHospital) {
      params.doctorCurrentHospital = doctorCurrentHospital?.label;
      params.doctorCurrentHospitalId = doctorCurrentHospital?.value;
    }
    if (illness) {
      params.confirmedDisease = illness?.map((item) => {
        if (item.value === item.label) {
          return { name: item.value }
        }
        return { id: item.value, name: item.label }
      }) ?? []
    }
    const res = await websiteAxios.post<any, any>('/api/user?endpoint=userUpdate', params, { headers: { tokenKey } }).catch((error) => {
      const { data } = error.response;
      if (data) {
        message.error(data.msg);
      }
    });
    if (res) {
      setUserVisible(false);
      if (!user) return;
      setUser({ userPhoto: user.userPhoto, doctorName: values.doctorName });
    }
  }, [tokenKey]);

  // 确诊疾病
  const [options, setOptions] = useState<Illness[]>([]);
  const fetchRef = useRef(0);

  const loadOptions = (value: string) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);

    websiteAxios.get<any, any>('/api/common?endpoint=getRareDiseaseList', { params: { keyword: value }, headers: { tokenKey } })
      .then((res) => {

        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(res);
      }).catch((error) => {
        const { data } = error.response;
        if (data) {
          message.error(data.msg);
        }
      });
  };

  const debounceFetcher = debounce(loadOptions, 200);
  const [user, setUser] = useAtom(userInfo);
  useEffect(() => {
    if (!user) return;
    const values = {
      ...user,
      doctorCurrentHospital: user.doctorCurrentHospitalId ? { value: user.doctorCurrentHospitalId, label: user.doctorCurrentHospital } : undefined,
      illness: user.confirmedDisease?.map((item) => {
        if (item.id) {
          return { value: item.id, label: item.name }
        }
        return { value: item.name, label: item.name }
      }) ?? []
    }
    userForm.setFieldsValue(values);
  }, [user]);

  // 基础信息
  const renderUserInfo = (
    <Modal
      centered
      title={null}
      footer={null}
      open={userVisible}
      onCancel={() => setUserVisible(false)}
    >
      <div className={styles.loginTitle}>用户信息</div>
      <Form layout="vertical" form={userForm} onFinish={onUserFinish}>
        <Form.Item label="用户类型" name="status" rules={[{ required: true, message: '请选择用户类型' }]}>
          <Select placeholder="请选择用户类型" options={USER_TYPE} />
        </Form.Item>
        <Form.Item label="姓名" name="doctorName">
          <Input placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item dependencies={['status']}>
          {({ getFieldValue }) => {
            const status = getFieldValue('status');
            switch (status) {
              case 1:
              case 2:
              case 3:
                return (
                  <Form.Item label="所属医院" name="doctorCurrentHospital">
                    <HospitalSelect />
                  </Form.Item>
                );
              case 5:
              case 8:
                return (
                  <Form.Item label="工作单位" name="companyOrOrganization">
                    <Input placeholder="请输入工作单位" />
                  </Form.Item>
                );
              case 6:
                return (
                  <Form.Item label="大学名称" name="universityName">
                    <Input placeholder="请输入大学名称" />
                  </Form.Item>
                );
              case 7:
                return (
                  <Form.Item label="确诊疾病" name="illness">
                    <Select
                      labelInValue
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="请选择确诊疾病"
                      onSearch={debounceFetcher}
                      // 将搜索的往前排
                      filterSort={(option, optionB, info) => {
                        // 如果info.searchValue等于option.chineseName，说明是搜索，将搜索的往后排
                        if (info.searchValue === optionB.chineseName) {
                          return -1;
                        }
                        return option.chineseName.localeCompare(optionB.chineseName);
                      }}
                      fieldNames={{ label: 'chineseName', value: 'id' }}
                      options={options}
                    />
                  </Form.Item>
                );
              default:
                return null;
            }
          }}
        </Form.Item>
        <div className={styles.submit}>
          <Button type="primary" style={{ width: '50%' }} htmlType="submit">提交</Button>
        </div>
      </Form>
    </Modal>
  );

  const items = [
    {
      key: '1',
      label: '退出',
      onClick: () => {
        setTokenKey(null);
        setUser(null);
      }
    }
  ];
  const isLogin = useAtomValue(isLoginAtom);
  // 登录信息
  const renderLoginInfo = useMemo(() => {
    if (!isLogin || !user) return (
      <Button size="small" type="primary" onClick={() => beforeLogin()}>登录</Button>
    );
    return (
      <Dropdown menu={{ items }}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {
              user.userPhoto
                ? <Image src={user.userPhoto} className={styles.photo} alt="" width={24} height={24} />
                : <Image src={defaultPhoto} className={styles.photo} alt="默认头像" width={24} height={24} />
            }
            <span className={styles.name}>{user?.doctorName}</span>
            <span className={styles.icon}>
              <CaretDownOutlined />
            </span>
          </Space>
        </a>
      </Dropdown>
    )
  }, [isLogin, user]);

  // 渲染导航
  return (
    <>
      <nav id="header" className={styles.PageHead__wrapper} aria-label="主导航">
        {/* 导航logo */}
        <div className={styles.PageHead}>
          <div className={styles.PageHead__logo}>
            <Link href="/" target='_self' aria-label="中国罕见病服务平台logo" >
              <Space split={<Divider type="vertical" />}>
                <Image
                  src={logo}
                  alt="中国罕见病服务平台"
                  className={styles.logo}
                />
                <Image
                  src={logo2}
                  alt="中国罕见病服务平台"
                  className={styles.logo}
                />
                <Image
                  src={logo3}
                  alt="中国罕见病服务平台"
                  className={styles.logo}
                />
              </Space>
            </Link>
          </div>
          <div className={styles.PageHead_suffix}>
            <Link href="/search" aria-label="搜索">
              <Image
                src={search}
                alt="搜索"
                className={styles.icon}
              />
            </Link>

            <Link href="https://app.chard.org.cn/" target="_blank" aria-label="跳转中国罕见病综合云服务平台" role='button'>
              <div className={styles.link_btn}>
                <Image
                  src={lmdt}
                  alt="中国罕见病综合云服务平台"
                  className="iconImage"
                />
                <span>中国罕见病综合云服务平台</span>
              </div>
            </Link>

            <Link href="https://upwards.chard.org.cn/" target="_blank" aria-label="跳转财政部项目管理平台" role='button'>
              <div className={styles.link_btn}>
                <Image
                  src={gjzx}
                  alt="财政部项目管理平台"
                  className="iconImage"
                />
                <span>财政部项目管理平台</span>
              </div>
            </Link>
            <Divider type="vertical" />
            {renderLoginInfo}
          </div>
        </div>
        {/* 主导航 */}
        <nav className={styles.PageHead__nav_wrapper}>
          <ul className={styles.PageHead__nav}>
            {navData?.map((item, index) => {
              if (item.link === "#popups_1") {
                return renderUpwardsNav();
              }
              if (item.link === "#center_review") {
                return renderCenterReview();
              }
              return (
                <li
                  key={item.id}
                  suppressHydrationWarning
                  className={classNames(styles.nav_item, !upwards && !centerReview && isActive(item) ? styles.active : '')}
                  aria-posinset={index + 1}
                  aria-setsize={navData?.length ?? 0}
                >
                  <Link
                    href={getHeaderLink(item)}
                    className={styles.nav_item_text}
                    aria-current={isActive(item) ? "page" : undefined}
                  >
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </nav>
      {/* 遮罩层 */}
      {upwards || centerReview ? <div aria-hidden={true} id={styles.mask}></div> : null}
      {/* 用于撑起导航栏的高度 */}
      <div aria-hidden={true} className={styles.navPlaceholder}></div>
      {/* 登录 */}
      {renderLoginModule}
      {/* 用户信息 */}
      {renderUserInfo}
    </>
  );
}
export default forwardRef(PageHead)